<style scoped lang="less">
	@import "../../variables.less";
	@import "../../resource/common.less";
	@currentWidth: 850px;
	@attachWidth: 730px;
	.header-bg-v2 {
		height: 40px;
		line-height: 30px;
		padding-left: 10px;
		display: -webkit-flex;
		display: flex;
		font-size: 16px;
		justify-content: space-between;
	}

	.el-table .cell {
		white-space: pre-line;
	}

	.el-table {
		margin-bottom: 4px;
		height: calc(100% - 40px);
	}

	#app {
		min-width: 1366px;
		height: 100%;
	}

	.el-table th {
		background-color: #103c56;
	}

	.el-table__footer-wrapper thead div, .el-table__header-wrapper thead div {
		background-color: #103c56;
		color: white;
	}

	.el-pager li.active {
		background-color: #103c56;
		border-color: #103c56;
	}

	.el-table__body-wrapper {
		overflow-y: auto;
		overflow-x: hidden;
	}

	.Wraning {
		color: red;
	}

	.isshow {
		display: none;
	}

	.ivu-table-overflowX::-webkit-scrollbar-thumb {
		background-color: #103c56;
	}

	.mytable::-webkit-scrollbar-thumb {
		background-color: #103c56;
	}

	.ivu-checkbox-checked .ivu-checkbox-inner {
		border-color: #103c56 !important;
		background-color: #103c56 !important;
	}

	.ivu-checkbox-indeterminate .ivu-checkbox-inner {
		border-color: #103c56 !important;
		background-color: #103c56 !important;
	}

	.ivu-page-item:hover {
		color: white !important;
		border-color: #103c56 !important;
		background-color: #103c56 !important;
	}

	.ivu-page-item {
		border-color: #103c56 !important;
	}

	.ivu-page-item-active a, .ivu-page-item-active:hover a {
		color: white !important;
	}

	.ivu-page-item:hover a {
		color: white !important;
	}

	.ivu-page-item-active {
		color: white !important;
		border-color: #103c56 !important;
		background-color: #103c56 !important;
	}

	.ivu-table th {
		height: 40px;
		white-space: nowrap;
		overflow: hidden;
		background-color: transparent;
		color: white;
	}

	.ivu-table td {
		background-color: transparent;
	}

	.ivu-table-filter i.on {
		color: dodgerblue;
	}

	.ivu-table-filter i {
		color: white;
	}

	.ivu-page-next:hover, .ivu-page-prev:hover {
		border-color: #103c56;
	}

	.ivu-table-cell .ivu-date-picker {
		color: white;
	}

	.ivu-table-wrapper {
		border: none;
	}

	.Epititle {
		color: white;
		padding: 0 15px;
		line-height: 40px;
		border-bottom: 1px solid white;
	}

	.facelist {
		height: 100%;
		font-size: 14px;
	}

	.facebox {
		width: 140px;
		height: 140px;
		border-radius: 50%;
		border: 1px solid #103c56;
		overflow: hidden;
	}

	.facebox img {
		width: 100%;
		display: block;
		margin: 0 auto;
	}

	.activebox {
		animation: mybig infinite 2s;
		box-shadow: 0 1px 4px 2px red;
	}

	@keyframes mybig {
		0% {
			transform: scale(0.5)
		}
		100% {
			transform: scale(1)
		}
	}

	.content-title {
		font-size: 14px;
		color: white;
	}

	.right-content {
		width: 98%;
		margin:  0 auto;
		background-color: rgba(2, 30, 43, 0.2);
		border: 1px solid #226B92;
		border-radius: 5px;
		padding: 10px;
	}

	.right-content:after {
		content: '';
		display: block;
		clear: both;
	}

	.right-content > div {
		float: left;
		display: block;
	}

	.faceRow {
		width: 100%;
		user-select: none;
		white-space: nowrap;
		overflow: hidden;
		overflow-x: auto;
	}

	.faceRow > div {
		width: 70px;
		margin: 0 10px;
		padding: 5px;
		height: 100px;
		text-align: center;
		display: inline-block;
		cursor: pointer;
	}

	.active {
		background: rgba(38,121,163,.6);
		border: 1px solid rgba(38,121,163,.9);
	}

</style>
<style>
	 .ivu-table-filter-list .ivu-table-filter-select-item-selected, .ivu-table-filter-list .ivu-table-filter-select-item-selected:hover {
		color:white;
		background: #2db7f5 !important;
	}
	 .ivu-table-filter-list .ivu-table-filter-select-item:hover{
		background: #2db7f5 !important;
		color: white;
	}
	 .ivu-table-filter-list .ivu-table-filter-select-item-selected, .ivu-table-filter-list .ivu-table-filter-select-item-selected {
		color: white;
	}
	.epidemic .ivu-table-filter i.on{
		 color:#2db7f5;
	 }
	 .ivu-poptip-rel .ivu-table-filter i{
		 color: white;
	 }
</style>
<template>
	<div class="epidemic mainContent">
		<div class="header-v2">
			<div class="header-bg-v2">
				<span class="title-v2 line_height32">疫情概况</span>
			</div>
		</div>
		<section style="height: calc(100% - 76px);" id="sectionbox">
			<div style="background: rgba(16, 30, 43, 0.3);">
				<div class="Epititle" style="margin-bottom: 15px;">
					<span class="content-title">人脸识别</span>
					<date-picker v-model="timerange"
								 @on-clear="handleCurrentChange(1)"
								 @on-clickoutside="showtime= false"
								 @on-change="handleCurrentChange(1)"
								 format="yyyy/MM/dd"
								 type="daterange"
								 placement="bottom-end"
								 clearable placeholder="请选择时间" style="width:200px;float: right;margin-top: 4px;">

					</date-picker>
				</div>
				<div class="right-content">
					<div class="facebox">
						<img :src="newApi + faceImg.pic">
					</div>
					<div style="width: calc(100% - 150px);margin-left: 10px;">
						<div class="facelist">
							<div class="content-title">姓名:{{faceImg.name}}</div>
							<div>性别: {{faceImg.gender | filterxb}}</div>
							<div class="faceRow">
								<div v-for="(item,index) in newgateEvents" @click="changePeople(item,index)" :class="{'active':isindex == index,'activebox':item.isclass}">
									<div class="facebox"
										 style="width: 60px;height: 60px;margin-bottom: 10px;">
										<img :src="newApi + item.pic">
									</div>
									<div>
										{{item.name}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="Epititle" style="margin-top: 20px;">
					<span class="content-title">进出数据</span>
					<Button type="info" style="float: right;margin-right: 10px;margin-bottom: 10px;margin-top:2px;"
							@click.native="callback()">返回
					</Button>
				</div>
				<Table :data="gateEvents"  :columns="columns" style="width: 100%" :height="tableHeight"
					   ref="mytable" :loading="listLoading">
				</Table>
				<Page :total="total" :page-size="pagesize" style="float: right;margin-top:4px;margin-right:5px;"
					  @on-change="handleCurrentChange"></Page>
			</div>
		</section>
	</div>
</template>
<script>
	import '../../lib/api'
	import request from "../../request";
	import {Page, Table, Button, DatePicker, Icon} from 'view-design'

	export default {
		name: "epidemic",
		filters: {
			filterxb: function (val) {
				if (val != null) {
					return val == 1 ? '男' : '女';
				}
			}
		},
		components: {
			Page,
			Table,
			Button,
			DatePicker,
			Icon
		},
		data() {
			return {
				newApi: 'http://47.103.195.147:8090',
				gateEvents: [],
				newgateEvents: [],
				listLoading: true,
				timerange: ['', ''],
				filterDevice: [],
				filterUser: [],
				columns: [
					{
						key: "deviceName",
						title: "设备",
						align: 'center',
						filters: [],
						filterMultiple: false,
						filterRemote: this.filterDevice,
						render: (h, params) => {
							return h('span', this.formatName(params.row))
						}
					},
					{
						key: "user",
						title: "姓名",
						filters: [],
						filterMultiple: false,
						filterRemote: this.filterUser,
						align: 'center',
						render: (h, params) => {
							return h('span', params.row.user.name)
						},
					},
					{
						key: "humantemp",
						title: "体温(℃)",
						width: 100,
						align: 'center',
						render: (h, params) => {
							return h("span", {
								style: {
									color: params.row.humantemp > 37.5 ? 'red' : ""
								}
							}, params.row.humantemp)
						}
					},
					{
						key: "srcts",
						title: "时间",
						align: 'center',
						render: (h, params) => {
							return h('span', this.formatTime(params.row))
						},
					}
				],
				faceImg: {
					name: "",
					fnonserver: "",
					gender: null,
					pic: ""
				},
				pagesize: 0,
				useraliaid: "",
				devicealiaid: "",
				tableHeight: 0,
				showtime: false,
				total: 0,
				page: 1,
				isindex: 0,
			}
		},
		methods: {
			callback() {
				this.useraliaid = '';
				this.devicealiaid = '';
				this.getGateEvents();
			},
			changePeople(item, index) {
				this.isindex = index;
				this.faceImg = item;
				this.useraliaid = item.aliaid;
				this.getGateEvents();
			},
			handleCurrentChange: function (val) {
				this.page = val;
				this.showtime = false;
				this.getGateEvents()
			},
			formatName: function (row) {
				return row.deviceName.replace(windowOrigin, '');
			},
			formatTime: function (row, column) {
				return util.formatDate.format(new Date(row.srcts * 1000), 'yyyy-MM-dd hh:mm:ss')
			},
			CreateHeader(name, type, key, h, param, seleIndex) {
				if (this.$refs.mytable) {
					return h("span", this.$refs.mytable.$scopedSlots.dropdown({
						name: name,
						type: type,
						key: key,
						seleIndex: seleIndex,
						param: param
					}))
				}
			},
			filterDevice(value) {
				this.devicealiaid = value[0];
				this.getGateEvents();
			},
			filterUser(value) {
				this.useraliaid = value[0];
				this.getGateEvents();
			},
			getGateEvents: function () {
				let para = {
					page: this.page,
					pagesize: this.pagesize,
					useraliaid: this.useraliaid,
					devicealiaid: this.devicealiaid,
					project_name: windowOrigin
				};
				if (this.timerange[0] && this.timerange[1]) {
					para.srcts__range = (Date.parse(this.timerange[0]) / 1000) + ',' + (Date.parse(new Date(this.timerange[1].Format('yyyy-MM-ddT') + '23:59:59')) / 1000);
				} else {
					delete para.srcts__range;
				}
				this.listLoading = true;
				apiGetGateEventPage(para).then((res) => {
					this.gateEvents = res.data.results;
					this.total = res.data.count;
					this.listLoading = false;
					if (res.data.results.length === 0) return;
					this.gateEvents.forEach(e => {
						e.peopleFace = e.user.face;
						e.deviceName = (e.device.buildingnum || '?') + (e.device.unitnum || '?');
						e.lockCat = (e.lockCat === '1') ? '已开门' : "未开门"
						e.media = (e.media === '00') ? '人脸识别' : (e.media === '01') ?
							'黑名单' : (e.media === '02') ? '门禁卡' : ''
						if (e.envtemp != null && e.envtemp !== ''
							|| e.humantemp != null && e.humantemp != '') {
							this.showTemp = true
						}
					})
				}).catch((error) => {
					this.listLoading = false;
					console.log(error);

				})
			},
		},
		mounted() {
			let height = document.getElementById('sectionbox').offsetHeight - 290;
			this.pagesize = Math.floor((height - 80) / 48);
			this.tableHeight = height;
		  		   	request({
				url: this.newApi + "/renlian/v1/rest/device/?buildingnum=" + windowOrigin,
					}).then((res) => {
						console.log(res)
						let arr = [];
						for (let i = 0; i < res.data.results.length; i++) {
							arr.push({
								label: res.data.results[i].unitnum,
								value: res.data.results[i].aliaid
							})
						}
						this.columns[0].filters = arr;
					})
					request({
						url: this.newApi + "/renlian/v1/rest/user/?buildingnum=" + windowOrigin,
					}).then((res) => {
						console.log(res)
						let arr = [];
						let newarr = [];
						let obj = {}
						for (let i = 0; i < res.data.results.length; i++) {
							arr.push({
								label: res.data.results[i].name,
								value: res.data.results[i].aliaid
							})
						}
						for (let i = 0; i < res.data.results.length; i++) {
							obj = res.data.results[i];
							if (isNaN(Number(obj.lasttemp))) {
								obj.isclass = false;
							} else {
								if (Number(obj.lasttemp) >= 37.5) {
									obj.isclass = true;
								} else {
									obj.isclass = false;
								}
							}
							newarr.push(obj);
						}
						this.newgateEvents = newarr;
						if (res.data.results[0]) {
							this.faceImg = res.data.results[0];
						}
						this.columns[1].filters = arr;
					});
					this.getGateEvents();
					this.isAdmin = parseInt(sessionStorage.getItem('isAdmin'))
		}
	}
</script>

<style scoped>

</style>
