import axios  from 'axios'
window.base = '';
window.API_NEW_VERSION = 'http://47.103.195.147:10010/api/v2.0/admin';
let newApi = 'http://47.103.195.147:8090';

window.getDeviceList = params => { return axios.get(newApi+'/renlian/v1/rest/device/', { params: params }); };
window.windowOrigin = '东方医院';
window.apiGetGateEventPage = params => { return axios.get(newApi + '/renlian/v1/rest/gateevent/', { params: params }); };
let api = 'http://47.103.195.147:10010/api/v2.0/admin/';
let obj = JSON.stringify({
	name: "admin",
	passwd: "e10adc3949ba59abbe56e057f20f883e"
});
function login(callback){
	let data = new FormData();
	data.append('name','admin');
	data.append('passwd','e10adc3949ba59abbe56e057f20f883e');
	axios({
		method:"POST",
		url:api+'/login',
		contentType:'application/json;charset=UTF-8',
		data:data,
		success:function(data){
			let account = data.myModel.account;
			let token = data.myModel.token;

			if (account === null) {
				let msg = "账号或密码错误"
				alert(msg)
			} else {
				sessionStorage.setItem('isAdmin', account.isAdmin);
				sessionStorage.setItem('accountId', account.id);
				sessionStorage.setItem('token', token);

			}
			axios.interceptors.request.use(
				config => {
					config.headers.Authorization = token;
					return config
				},
				error => {
					return Promise.reject(error)
				}
			);

		}.bind(this)
	});
}
var SIGN_REGEXP = /([yMdhsm])(\1*)/g;
var DEFAULT_PATTERN = 'yyyy-MM-dd';
function padding(s, len) {
	var len = len - (s + '').length;
	for (var i = 0; i < len; i++) { s = '0' + s; }
	return s;
};
window.util = {
	getQueryStringByName: function (name) {
		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
		var r = window.location.search.substr(1).match(reg);
		var context = "";
		if (r != null)
			context = r[2];
		reg = null;
		r = null;
		return context == null || context == "" || context == "undefined" ? "" : context;
	},
	formatDate: {
		format: function (date, pattern) {
			pattern = pattern || DEFAULT_PATTERN;
			return pattern.replace(SIGN_REGEXP, function ($0) {
				switch ($0.charAt(0)) {
					case 'y':
						return padding(date.getFullYear(), $0.length);
					case 'M':
						return padding(date.getMonth() + 1, $0.length);
					case 'd':
						return padding(date.getDate(), $0.length);
					case 'w':
						return date.getDay() + 1;
					case 'h':
						return padding(date.getHours(), $0.length);
					case 'm':
						return padding(date.getMinutes(), $0.length);
					case 's':
						return padding(date.getSeconds(), $0.length);
				}
			});
		},
		parse: function (dateString, pattern) {
			var matchs1 = pattern.match(SIGN_REGEXP);
			var matchs2 = dateString.match(/(\d)+/g);
			if (matchs1.length == matchs2.length) {
				var _date = new Date(1970, 0, 1);
				for (var i = 0; i < matchs1.length; i++) {
					var _int = parseInt(matchs2[i]);
					var sign = matchs1[i];
					switch (sign.charAt(0)) {
						case 'y':
							_date.setFullYear(_int);
							break;
						case 'M':
							_date.setMonth(_int - 1);
							break;
						case 'd':
							_date.setDate(_int);
							break;
						case 'h':
							_date.setHours(_int);
							break;
						case 'm':
							_date.setMinutes(_int);
							break;
						case 's':
							_date.setSeconds(_int);
							break;
					}
				}
				return _date;
			}
			return null;
		}

	},

	BlobB64: {}
};



